<template>
    <b-card :title="$t('profile')">
        <ul class="list-unstyled">
            <!--<li>
                <b-img :src="user.avatar" rounded="circle" width="70"></b-img>
            </li>-->
            <li><strong>{{ $t('pib') }}: </strong>{{ user.first_name }} {{ user.last_name }} {{ user.surname }}</li>
            <li><strong>{{ $t('email') }}: </strong>{{ user.email }}</li>
            <li><strong>{{ $t('phone') }}: </strong>{{ user.phone }}</li>
            <li v-if="user_card_number"><strong>{{ $t('card_number') }}: </strong>{{ user.card_number }}</li>
        </ul>
    </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('profile') }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    }
}
</script>
